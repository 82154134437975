import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { navigateTo } from "gatsby-link";

import Layout from "../../components/layout";
import MainBg from "../../components/mainBg";
import NewsletterSignup from "../../components/newsletterSignup";

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

class BusinessClaim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Business Insurance Claim Form | Lewis Mohr"
          meta={[
            {
              name: "description",
              content:
                "If you or someone at your company was involved in an incident, please fill out the form below. We will be in touch within 1 business day. If you need immediate assistance please give us a call."
            }
          ]}
        />
        <MainBg
          mainBg={this.props.data.mainBg}
          imgStyle={{ objectPosition: "bottom" }}
          alt="Business buildings"
        />
        <div className="site-body page-content">
          <h1>File a Business Claim</h1>
          <p>
            If you or someone at your company was involved in an incident, please fill out the form below. We will be in touch within 1 business day. If you need immediate assistance please give us a call at: <a className="no-break" href="tel:2252931086">225-293-1086</a>.
          </p>
          <div className="grid grid-gutters-lg">
            <div className="grid-cell">
              <form
                name="Lewis Mohr Business Claims"
                method="post"
                action="/file-a-claim/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <div className="grid grid-gutters-sides grid-full med-grid-1of2">
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="file-upload" />
                <p hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </p>
                <div className="grid-cell">
                <label>
                  Your Name
                  <sup>*</sup>
                <input
                  type="text"
                  id="nameField"
                  name="name"
                  placeholder=""
                  onChange={this.handleChange}
                  required
                />
                </label>
                </div>
                <div className="grid-cell">
                <label>
                  Company Name
                  <sup>*</sup>
                <input
                  type="text"
                  id="companyField"
                  name="companyName"
                  placeholder=""
                  onChange={this.handleChange}
                  required
                />
                </label>
                </div>
                <div className="grid-cell">
                <label>
                  Email
                  <sup>*</sup>
                <input
                  type="email"
                  id="emailField"
                  name="email"
                  placeholder=""
                  onChange={this.handleChange}
                  required
                />
                </label>
                </div>
                <div className="grid-cell">
                <label>
                  Phone
                  <sup>*</sup>
                <input
                  type="phone"
                  id="phoneField"
                  name="phone"
                  placeholder=""
                  onChange={this.handleChange}
                  required
                />
                </label>
                </div>
                <div className="grid-cell">
                <label>
                  Location of Incident
                  <sup>*</sup>
                <input
                  type="text"
                  id="locationField"
                  name="Incident Location"
                  placeholder=""
                  onChange={this.handleChange}
                  required
                />
                </label>
                </div>
                <div className="grid-cell">
                <label>
                  Date of Loss
                  <sup>*</sup>
                <input
                  type="date"
                  id="dateField"
                  name="Date of Loss"
                  placeholder=""
                  onChange={this.handleChange}
                  required
                />
                </label>
                </div>
                <div className="grid-cell u-full">                
                <label>
                  Upload Pictures of Incident (Optional)<br />
                  <input
                  type="file"
                  name="attachment1"
                  onChange={this.handleAttachment}
                />
                <input
                  type="file"
                  name="attachment2"
                  onChange={this.handleAttachment}
                />
                <input
                  type="file"
                  name="attachment3"
                  onChange={this.handleAttachment}
                />
                <input
                  type="file"
                  name="attachment4"
                  onChange={this.handleAttachment}
                />
                <input
                  type="file"
                  name="attachment5"
                  onChange={this.handleAttachment}
                />
                <input
                  type="file"
                  name="attachment6"
                  onChange={this.handleAttachment}
                />
                </label>
                </div>
                <div className="grid-cell u-full">
                <label>                  
                  Describe what happened
                  <sup>*</sup>
                <textarea
                  type="message"
                  id="messageField"
                  name="message"
                  placeholder=""
                  onChange={this.handleChange}
                  required
                />
                </label>
                </div>
                <div className="grid-cell u-full">
                <div className="disclaimer">
                  Note: No coverage may be bound or altered by email or voice mail.
                  You must speak with a representative.
                </div>
                </div>
                <div className="grid-cell u-full">
                  <button type="submit">Send Claim Information</button>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default BusinessClaim;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "file-a-claim-business-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-28.jpg" }) {
      ...mainBg
    }
  }
`;
